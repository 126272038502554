<template>
  <div class="filter-modal-container">
    <div class="columns is-gapless">
      <div class="column header-page">
        <span>Select</span>
        <div class="close-button" @click="clickClose">
          <img src="/images/btn-close@2x.png" alt="close" width="48" />
        </div>
      </div>
    </div>
    <div class="columns is-gapless is-centered">
      <div class="column is-justify-content-start">
        <div class="container-component-filters">
          <RetroContent
            v-show="isRetroMode && !showTeams && !showPlayers"
            :is-retro-mode="isRetroMode"
            :is-modal-visible="show"
          />
          <FilterLeague
            :is-retro-mode="isRetroMode"
            :show="showLeague"
            :is-modal-visible="show"
            @selectLeague="selectLeague"
          />
          <FilterTeams :show="showTeams" :is-retro-mode="isRetroMode" @selectTeam="selectTeam" :key="dataUpdate + 2" />
          <template v-if="type === 1">
            <FilterPlayers
              :show="showPlayers"
              :is-retro-mode="isRetroMode"
              @selectPlayer="selectPlayer"
              :key="dataUpdate + 1"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import FilterLeague from '@/components/Sections/MediaPlayer/FilterLeague';
import FilterTeams from '@/components/Sections/MediaPlayer/FilterTeams';
import FilterPlayers from '@/components/Sections/MediaPlayer/FilterPlayer';
export default {
  name: 'ContentModal',
  components: {
    FilterLeague,
    FilterTeams,
    FilterPlayers,
    RetroContent: () => import('@/components/Sections/MediaPlayer/RetroContent'),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataUpdate: 0,
      showLeague: true,
      showTeams: false,
      showPlayers: false,
      type: 1, //1 player, 0 team
    };
  },
  computed: {},
  async created() {
    var myDiv = document.getElementsByClassName('container-component-filters');
    myDiv.scrollTop = 0;
  },
  watch: {
    show() {
      if (!this.show) {
        this.showPlayers = false;
        this.showLeague = true;
        this.showTeams = false;
        this.dataUpdate += 1;
      }
    },
  },
  methods: {
    ...mapActions('mediaGeneral', ['getPlayersByTeam']),
    ...mapMutations('mediaGeneral', ['resetPlayer']),
    selectLeague(type) {
      this.showLeague = false;
      this.showTeams = true;
      this.type = type;
    },
    selectTeam() {
      if (this.type === 1) {
        this.showTeams = false;
        this.getPlayersByTeam();
        this.showPlayers = true;
      } else {
        this.showPlayers = false;
        this.showLeague = true;
        this.showTeams = false;
        this.resetPlayer();
        this.$emit('team-selected');
        this.$emit('close');
        this.dataUpdate += 1;
      }
    },
    selectPlayer() {
      this.showPlayers = false;
      this.showLeague = true;
      this.$emit('player-selected');
      this.$emit('close');
      this.dataUpdate += 1;
    },
    clickClose() {
      this.showPlayers = false;
      this.showLeague = true;
      this.showTeams = false;
      this.$emit('close');
      this.dataUpdate += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.filter-modal-container {
  background: white;
  min-height: 812px;
  border-radius: 19px;
  border-top-left-radius: 24px;
  border-top-right-radius: 0;
  overflow-x: hidden;
  @media screen and (max-width: 1553px) {
    width: 1053px;
    margin: 0 auto;
  }
  @media screen and (max-width: 1053px) {
    width: 953px;
    margin: 0 auto;
  }
  @media screen and (max-width: 953px) {
    width: 853px;
    margin: 0 auto;
  }
  @media screen and (max-width: 853px) {
    width: 768px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: 653px;
    margin: 0 auto;
  }
  @media screen and (max-width: 653px) {
    width: 553px;
    margin: 0 auto;
  }
  @media screen and (max-width: 553px) {
    width: 453px;
    margin: 0 auto;
  }

  @media screen and (max-width: 453px) {
    width: 100%;
    margin: 0 auto;
    border-radius: 0px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}
.speech-invesment {
  font-family: Avenir-Regular;
  max-width: 550px;
  margin: 0 15px;
  color: #494a4b;
  span:first-child {
    font-family: Avenir-Demi;
  }
}
.text-close-button {
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.close-button {
  display: flex;
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  right: 0;
  top: 0;

  @media screen and (max-width: 768px) {
    border-radius: 33px;
    width: 32px;
    height: 32px;
    padding: 0 0;
    font-size: 28px;
    text-align: center;
    justify-content: center;
  }
  @media screen and (max-width: 453px) {
    width: 42px;
    height: 42px;
  }
}
.video {
  width: 100%;
  max-width: 700px;
}
.header-page {
  height: 80px;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  justify-content: center;
  position: relative;
  span {
    height: 32px;
    font-family: 'Avenir-Demi';
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.84;
    letter-spacing: -0.8px;
    text-align: center;
    color: #3e3e3e;
    @media screen and (max-width: 453px) {
      font-size: 38px !important;
    }
  }
}
.columns {
  width: 100%;
  .column {
    padding: 40px 50px;
  }
}
.button {
  font-family: Avenir-Pro-Bold;
  padding: 15px 40px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 3.07;
  letter-spacing: -0.08px;
  text-align: center;
  border: none;
  color: #494a4b;
}
.button-links {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.is-selected {
  background-color: #efefef;
}
.is-justify-content-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
@media screen and (min-width: 769px) {
  .is-justify-content-start {
    width: 40%;
  }
}
.mt-40 {
  margin-top: 40px !important;
}
.outline-right {
  position: relative;
}
.border-right {
  width: 2px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #cdcdcd;
}
@media screen and (max-width: 880px) {
  .button {
    padding: 5px 15px;
  }
  section {
    position: relative;
  }
  @media screen and (max-width: 450px) {
    .header-page span {
      font-size: 22px;
    }
    .close-button {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
    }
  }
}
@media screen and (max-width: 768px) {
  .mt-40 {
    margin-top: 0 !important;
  }
  .columns {
    width: 100%;
    .column {
      padding: 0;
    }
  }
  .button-links {
    padding: 0 0 10px;
  }
  .button {
    padding: 5px 10px;
  }
  section {
    position: relative;
  }
}
.container-component-filters::-webkit-scrollbar {
  width: 9px; /* width of the entire scrollbar */
}

.container-component-filters::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

.container-component-filters::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* color of the scroll thumb */
  border-radius: 30px; /* roundness of the scroll thumb */
  border: 1px solid #a3a3a3; /* creates padding around scroll thumb */
}
.container-component-filters {
  width: 1053px;
  height: 680px;
  margin: 0 auto;
  overflow-y: auto !important;
  overflow-x: hidden !important;

  @media screen and (max-height: 820px) {
    height: 640px;
  }
  @media screen and (max-height: 780px) {
    height: 600px;
  }
  @media screen and (max-height: 700px) {
    height: 540px;
  }
  @media screen and (max-height: 650px) {
    height: 480px;
  }
  @media screen and (max-height: 610px) {
    height: 430px;
  }
  @media screen and (max-height: 580px) {
    height: 400px;
  }
  @media screen and (max-height: 550px) {
    height: 370px;
  }
  @media screen and (max-height: 520px) {
    height: 340px;
  }
  @media screen and (max-height: 490px) {
    height: 310px;
  }
  @media screen and (max-height: 460px) {
    height: 280px;
  }
  @media screen and (max-height: 430px) {
    height: 280px;
  }
  @media screen and (max-height: 400px) {
    height: 250px;
  }
  @media screen and (max-height: 370px) {
    height: 220px;
  }
  @media screen and (max-height: 340px) {
    height: 190px;
  }
  @media screen and (max-width: 1053px) {
    width: 953px;
  }
  @media screen and (max-width: 953px) {
    width: 853px;
  }
  @media screen and (max-width: 853px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 653px;
  }
  @media screen and (max-width: 653px) {
    width: 553px;
  }
  @media screen and (max-width: 553px) {
    width: 453px;
  }
  @media screen and (max-width: 453px) {
    width: 100%;
  }
  @media screen and (max-width: 353px) {
    width: 100%;
  }
}
</style>
