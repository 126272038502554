<template>
  <div>
    <div class="mediaplayer-filters-players" v-if="show">
      <div class="mediaplayer-filters-players__title">Player:</div>
      <div class="mediaplayer-filters-players__playerscontainer-pc">
        <template v-for="(players, key) in getPlayers">
          <div class="player-item" @click="clickPlayer(players['data'])" :key="key">
            <div class="player-item__info">
              <div class="player-item__info__num"></div>
              <div class="player-item__info__years"></div>
            </div>
            <div class="player-item__img">
              <div class="player-item__img__circle"></div>
              <div class="player-item__img__jersey" :style="getBackgroundImage(players)"></div>
              <div
                class="player-item__img__number"
                :style="
                  'color:' +
                    getColor(players['data'].team_id) +
                    '; text-shadow: ' +
                    getShadow(players['data'].team_id) +
                    ';'
                "
              ></div>
            </div>
            <div class="player-item__name">{{ players['surname'] }}<br />{{ players['name'] }}</div>
            <div class="player-item__position">{{ players['data'].position_name }}</div>
          </div>
        </template>
      </div>
      <div class="mediaplayer-filters-players__playerscontainer-movil">
        <BCarousel :autoplay="false" :pause-info="false" :arrow="false" :indicator-inside="false">
          <template v-for="(playerGroup, key) in getPlayersMobile">
            <BCarouselItem :key="key">
              <template v-for="(players, keyPlayer) in playerGroup">
                <div class="player-item" @click="clickPlayer(players['data'])" :key="keyPlayer">
                  <div class="player-item__info">
                    <div class="player-item__info__num"></div>
                    <div class="player-item__info__years"></div>
                  </div>
                  <div class="player-item__img">
                    <div class="player-item__img__circle"></div>
                    <div class="player-item__img__jersey" :style="getBackgroundImage(players)"></div>
                  </div>
                  <div class="player-item__name">{{ players['surname'] }}<br />{{ players['name'] }}</div>
                  <div class="player-item__position">{{ players['data'].position_name }}</div>
                </div>
              </template>
            </BCarouselItem>
          </template>
        </BCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorJersey: '',
      arrayColors: {
        //liga mx
        1: { color: '#1B2956', shadow: '#FFFFFF' },
        2: { color: '#1B2956', shadow: '#FFFFFF' },
        4: { color: '#FFFFFF', shadow: 'transparent' },
        5: { color: '#FFFFFF', shadow: 'transparent' },
        7: { color: '#1B2956', shadow: '#FFFFFF' },
        8: { color: '#FFFFFF', shadow: 'transparent' },
        9: { color: '#1B2956', shadow: '#FFFFFF' },
        10: { color: '#1B2956', shadow: '#FFFFFF' },
        11: { color: '#FFC68F', shadow: 'transparent' },
        12: { color: '#FFFFFF', shadow: 'transparent' },
        13: { color: '#FFFFFF', shadow: 'transparent' },
        14: { color: '#1B2956', shadow: '#FFFFFF' },
        16: { color: '#FFFFFF', shadow: 'transparent' },
        17: { color: '#FFFFFF', shadow: 'transparent' },
        18: { color: '#FFFFFF', shadow: 'transparent' },
        173: { color: '#FFFFFF', shadow: 'transparent' },
        205: { color: '#147F55', shadow: '#FFFFFF' },
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        //expan
        482: { color: '#1D1D1D', shadow: '#FFFFFF' }, //alebrijes
        3: { color: '#FFFFFF', shadow: 'transparent' }, //atalante
        3161: { color: '#1D1D1D', shadow: '#FFFFFF' }, //cancun
        908: { color: '#1B2956', shadow: '#FFFFFF' }, //cimarrones
        3162: { color: '#1B2956', shadow: '#FFFFFF' }, //morellia
        557: { color: '#1B2956', shadow: '#FFFFFF' }, //celaya
        554: { color: '#FFFFFF', shadow: 'transparent' }, //correaceminos
        195: { color: '#FFFFFF', shadow: 'transparent' }, //dorados
        757: { color: '#FFFFFF', shadow: 'transparent' }, //mineros
        3166: { color: '#FFC68F', shadow: 'transparent' }, //tabasco
        3328: { color: '#1B2956', shadow: '#FFFFFF' }, //rayados
        3163: { color: '#1B2956', shadow: '#FFFFFF' }, //tapatio
        3164: { color: '#FFFFFF', shadow: 'transparent' }, //tepatitaln
        3165: { color: '#FFFFFF', shadow: 'transparent' }, //tlaxcala
        1603: { color: '#1D1D1D', shadow: '#FFFFFF' }, //tampico
        235: { color: '#1D1D1D', shadow: '#FFFFFF' }, //udg
        592: { color: '#1B2956', shadow: '#FFFFFF' }, //tapachula
        //femenil
        2734: { color: '#1B2956', shadow: '#FFFFFF' }, //america
        2735: { color: '#FFFFFF', shadow: 'transparent' }, //atlas
        2978: { color: '#FFFFFF', shadow: 'transparent' }, //san luis
        2749: { color: '#FFFFFF', shadow: 'transparent' }, //xolos
        2737: { color: '#FFFFFF', shadow: 'transparent' }, //cruz azul
        3018: { color: '#FFFFFF', shadow: 'transparent' }, //juarez
        2736: { color: '#1B2956', shadow: '#FFFFFF' }, //chivas
        2738: { color: '#FFFFFF', shadow: 'transparent' }, //leon
        2740: { color: '#FFFFFF', shadow: 'transparent' }, //mazatlan
        2741: { color: '#1B2956', shadow: '#FFFFFF' }, //monterrey
        2742: { color: '#147F55', shadow: '#FFFFFF' }, //necaxa
        2743: { color: '#1B2956', shadow: '#FFFFFF' }, //pachuca
        2744: { color: '#1B2956', shadow: '#FFFFFF' }, //puebla
        2746: { color: '#FFFFFF', shadow: 'transparent' }, //queretaro
        2747: { color: '#1B2956', shadow: '#FFFFFF' }, //santos
        2748: { color: '#FFFFFF', shadow: 'transparent' }, //tigres
        2750: { color: '#FFFFFF', shadow: 'transparent' }, //toluca
        2745: { color: '#FFC68F', shadow: 'transparent' }, //pumas
      },
      arrayColorsRetro: {
        //liga mx
        // chivas
        1: { color: '#1B2A56', shadow: '#FFFFFF' },
        // america
        2: { color: '#1B2A56', shadow: '#FFFFFF' },
        // atlante
        3: { color: '#FFFFFF', shadow: 'transparent' },
        // atlas
        4: { color: '#FFFFFF', shadow: 'transparent' },
        // cruz azul
        5: { color: '#1B2A56', shadow: '#FFFFFF' },
        // jaguares
        6: { color: '#1d1d1d', shadow: '#ffffff' },
        // monterrey
        7: { color: '#1B2a56', shadow: '#FFFFFF' },
        // morelia - mazatlan
        8: { color: '#1d1d1d', shadow: '#ffffff' },
        // pachuca
        9: { color: '#1b2a56', shadow: '#FFFFFF' },
        // puebla
        10: { color: '#1B2a56', shadow: '#FFFFFF' },
        // pumas
        11: { color: '#ffc78f', shadow: 'transparent' },
        // queretaro
        12: { color: '#FFFFFF', shadow: 'transparent' },
        // san luis
        13: { color: '#FFFFFF', shadow: 'transparent' },
        // santos
        14: { color: '#1b2a56', shadow: '#FFFFFF' },
        // tigres
        16: { color: '#1b2a56', shadow: '#ffffff' },
        // tijuana
        17: { color: '#FFFFFF', shadow: 'transparent' },
        // toluca
        18: { color: '#FFFFFF', shadow: 'transparent' },
        // leon
        173: { color: '#FFFFFF', shadow: 'transparent' },
        // necaxa
        205: { color: '#137f54', shadow: '#FFFFFF' },
        // veracruz
        469: { color: '#1b2a56', shadow: '#FFFFFF' },
        // celaya
        557: { color: '#1b2a56', shadow: '#FFFFFF' },
        // juarez
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        // toros neza
        3396: { color: '#1d1d1d', shadow: '#ffffff' },
        // tecos
        3397: { color: '#1b2a56', shadow: '#ffffff' },
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters('mediaGeneral', ['getPlayers', 'getPlayersMobile']),
  },
  methods: {
    getBackgroundImage(player) {
      return `background-image: url(${player.data.player_photo})`;
    },
    getColor(team) {
      return this.isRetroMode ? this.arrayColorsRetro[team].color : this.arrayColors[team].color;
    },
    getShadow(team) {
      const shadow = this.isRetroMode ? this.arrayColorsRetro[team].shadow : this.arrayColors[team].shadow;
      return (
        '2px 0 0 ' +
        shadow +
        ', -2px 0 0 ' +
        shadow +
        ', 0 2px 0 ' +
        shadow +
        ', 0 -2px 0 ' +
        shadow +
        ', 1px 1px ' +
        shadow +
        ', -1px -1px 0 ' +
        shadow +
        ', 1px -1px 0 ' +
        shadow +
        ', -1px 1px 0 ' +
        shadow +
        '; -webkit-text-shadow: 2px 0 0 ' +
        shadow +
        ', -2px 0 0 ' +
        shadow +
        ', 0 2px 0 ' +
        shadow +
        ', 0 -2px 0 ' +
        shadow +
        ', 1px 1px ' +
        shadow +
        ', -1px -1px 0 ' +
        shadow +
        ', 1px -1px 0 ' +
        shadow +
        ', -1px 1px 0 ' +
        shadow +
        '; -ms-text-shadow: 2px 0 0 ' +
        shadow +
        ', -2px 0 0 ' +
        shadow +
        ', 0 2px 0 ' +
        shadow +
        ', 0 -2px 0 ' +
        shadow +
        ', 1px 1px ' +
        shadow +
        ', -1px -1px 0 ' +
        shadow +
        ', 1px -1px 0 ' +
        shadow +
        ', -1px 1px 0 ' +
        shadow +
        '; -o-text-shadow: 2px 0 0 ' +
        shadow +
        ', -2px 0 0 ' +
        shadow +
        ', 0 2px 0 ' +
        shadow +
        ', 0 -2px 0 ' +
        shadow +
        ', 1px 1px ' +
        shadow +
        ', -1px -1px 0 ' +
        shadow +
        ', 1px -1px 0 ' +
        shadow +
        ', -1px 1px 0 ' +
        shadow +
        ';'
      );
    },
    ...mapMutations('mediaGeneral', ['setPlayer']),
    clickPlayer(val) {
      this.setPlayer(val);
      this.$emit('selectPlayer');
    },
  },
  components: {
    BCarousel,
    BCarouselItem,
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 453px) {
  .columns.is-gapless:not(:last-child) {
    margin-bottom: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
.player-item {
  float: left;
  width: 156px;
  height: 144px;
  margin: 0 13px 23px 0;
  border-radius: 8px;
  border: solid 1px #d1d1d1;
  background-color: #fff;
  &:nth-child(6n + 6),
  :nth-child(6) {
    margin: 0 0 0 0;
  }
  @media screen and (max-width: 1053px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 31px 18px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 0 18px 0;
    }
    margin: 0 31px 18px 0;
  }
  @media screen and (max-width: 953px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 7px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 0px 23px 0;
    }
    margin: 0 7px 23px 0;
  }
  @media screen and (max-width: 853px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 19px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 19px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 0 19px 0;
    }
    margin: 0 33px 19px 0;
  }
  @media screen and (max-width: 768px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 6px 17px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 6px 17px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 0px 17px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 6px 17px 0;
    }
    margin: 0 6px 17px 0;
    width: 151px;
  }
  @media screen and (max-width: 653px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 16px 14px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 16px 14px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 16px 14px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 0 14px 0;
    }
    margin: 0 16px 14px 0;
    width: 156px;
  }
  @media screen and (max-width: 553px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 3px 8px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 3px 8px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 3px 8px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 0 8px 0;
    }
    margin: 0 3px 8px 0;

    width: 143px;
  }
  @media screen and (max-width: 453px) {
    .columns.is-gapless:not(:last-child) {
      margin-bottom: 0px !important;
    }
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 45px;
    width: 156px;
  }
  @media screen and (max-width: 438px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 37px;

    width: 156px;
  }
  @media screen and (max-width: 418px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 29px;
    width: 156px;
  }
  @media screen and (max-width: 406px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 21px;
    width: 156px;
  }
  @media screen and (max-width: 392px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 28px;
    width: 156px;
  }
  @media screen and (max-width: 386px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 25px;
    width: 156px;
  }
  @media screen and (max-width: 376px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 20px;
    width: 156px;
  }
  @media screen and (max-width: 366px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 10px;
    width: 156px;
  }
  @media screen and (max-width: 353px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 38px;
    width: 122px;
  }
  @media screen and (max-width: 343px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 32px;

    width: 122px;
  }
  @media screen and (max-width: 333px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 27px;

    width: 122px;
  }
  @media screen and (max-width: 323px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 23px;

    width: 122px;
  }
  @media screen and (max-width: 313px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 19px;

    width: 122px;
  }
  @media screen and (max-width: 303px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 15px;

    width: 122px;
  }
  &:hover {
    filter: grayscale(0%);
    border: solid 1px #7ed321;
    cursor: pointer;
  }
  &__info {
    width: 100%;
    height: 19px;
    display: inline-flex;
    justify-content: space-between;
    &__num {
      padding-left: 10px;
      width: 10px;
      height: 19px;
      font-family: 'Avenir-Pro-Bold';
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 19px;
      letter-spacing: normal;
      color: #57595d;
    }
    &__years {
      padding-right: 10px;
      margin-top: 3px;
      width: 45px;
      height: 12px;
      font-family: 'Avenir-Demi';
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 10px;
      letter-spacing: normal;
      color: #57595d;
      &__text {
        font-size: 9px;
        opacity: 0.7px;
      }
      @media screen and (max-width: 453px) {
        margin-top: 3px !important;
      }
    }
  }
  &__img {
    width: 80px;
    height: 78px;
    position: relative;
    margin: 0 auto;

    margin-top: -20px !important;
    @media screen and (max-width: 453px) {
      margin: 0 auto !important;
      margin-top: -20px !important;
    }
    &__jersey {
      width: 80px;
      height: 78px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      position: absolute;
    }
    &__circle {
      width: 68px;
      height: 68px;
      left: 6px;
      top: 15px;
      background: #f1f1f1;
      border-radius: 70px;
      position: absolute;
    }
    &__number {
      width: 44px;
      height: 34px;
      margin-left: 18px !important;
      margin-top: 24px !important;
      position: absolute;
      // color: white;
      font-size: 23px;
      text-align: center;
      line-height: 34px;
      font-family: 'Avenir-Demi';
    }
  }
  &__name {
    width: 156px;
    height: 32px;
    margin-top: 6px;
    font-family: 'Avenir-Demi';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3c3c3c;
    @media screen and (max-width: 768px) {
      margin: 5px 0px 0px 0px !important;
      width: 151px;
    }
    @media screen and (max-width: 653px) {
      margin: 5px 0px 0px 0px !important;
      width: 156px;
    }
    @media screen and (max-width: 553px) {
      width: 142px;
    }
    @media screen and (max-width: 453px) {
      margin: 5px 0px 0px 5px !important;
    }
    @media screen and (max-width: 353px) {
      width: 120px;
      margin: 5px 0px 0px 0px !important;
    }
  }
  &__position {
    width: 156px;
    height: 14px;
    font-family: 'Avenir-Regular';
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #57595d;
    @media screen and (max-width: 768px) {
      margin: 0px 0px 0px 0px !important;
      width: 151px;
    }
    @media screen and (max-width: 653px) {
      margin: 0px 0px 0px 0px !important;
      width: 156px;
    }
    @media screen and (max-width: 553px) {
      width: 142px;
    }
    @media screen and (max-width: 453px) {
      margin: 0px 0px 0px 5px !important;
    }
    @media screen and (max-width: 353px) {
      width: 120px;
      margin: 0px 0px 0px 0px !important;
    }
  }
}
.mediaplayer-filters-players {
  width: 96%;
  height: auto;
  display: inline-block;
  @media screen and (max-width: 768px) {
    width: 98%;
  }
  @media screen and (max-width: 653px) {
    width: 92%;
  }
  @media screen and (max-width: 553px) {
    width: 98%;
  }
  &__title {
    width: 100%;
    height: 33px;
    margin-bottom: 24px;
    margin-top: 0px;
    font-family: 'Avenir-Demi';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: -0.13px;
    text-align: center;
    color: #3e3e3e;

    @media screen and (max-width: 453px) {
      font-size: 20px;
    }
    @media screen and (max-width: 353px) {
      font-size: 16px;
    }
  }
  &__playerscontainer-pc {
    width: 100%;
    height: auto;
    display: inline-block;
    @media screen and (max-width: 453px) {
      display: none;
    }
  }
  &__playerscontainer-movil {
    text-align: center;
    display: none;
    background: #ececec;
    padding-top: 25px;
    padding-bottom: 25px;
    @media screen and (max-width: 453px) {
      display: inline-block;
    }
  }
}
</style>
