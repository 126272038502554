<template>
  <BModal
    :width="1553"
    :active="show"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :can-cancel="['outside', 'escape']"
    @close="$emit('close')"
  >
    <ContentModal
      :show="show"
      :is-retro-mode="isRetroMode"
      @player-selected="$emit('player-selected')"
      @team-selected="$emit('team-selected')"
      @close="$emit('close')"
    />
  </BModal>
</template>

<script>
import ContentModal from '@/components/Sections/MediaPlayer/ContentModal';
import { BModal } from 'buefy/dist/esm/modal';
export default {
  name: 'ModalIndex',
  components: {
    BModal,
    ContentModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.modal-content,
.modal-card {
  z-index: 20px;
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 30px) !important;
  }
  @media screen and (max-width: 440px) {
    max-height: calc(100vh - 50px) !important;
    margin-top: 50px !important;
  }
}
</style>
