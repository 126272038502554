<template>
  <div>
    <!-- VISTA 2 -->
    <div class="mediaplayer-filters-league" v-if="show">
      <div v-if="!isRetroMode" class="mediaplayer-filters-league__title">1. Liga y el torneo deseado:</div>
      <div v-if="!isRetroMode" class="mediaplayer-filters-league__selectorscontainer">
        <div class="mediaplayer-filters-league__selectorscontainer__abs"></div>
        <div class="mediaplayer-filters-league__selectorscontainer__league">
          <div class="mediaplayer-filters-league__selectorscontainer__league__label">Liga:</div>
          <div class="mediaplayer-filters-league__selectorscontainer__league__selector">
            <BSelect placeholder="Selecciona uno" :value="tournamentSelected" expanded @input="setTournamentSelected">
              <option v-for="tournament in listTournaments" :key="tournament.id" :value="tournament">
                {{ tournament.name }}
              </option>
            </BSelect>
          </div>
        </div>
        <div class="mediaplayer-filters-league__selectorscontainer__sep">
          <div class="mediaplayer-filters-league__selectorscontainer__sep__divisor"></div>
        </div>
        <div class="mediaplayer-filters-league__selectorscontainer__season">
          <div class="mediaplayer-filters-league__selectorscontainer__season__label">Torneo:</div>
          <div class="mediaplayer-filters-league__selectorscontainer__season__selector">
            <BSelect v-model="seasonSelected" placeholder="Selecciona uno" expanded>
              <option v-for="season in tournamentSelected.seasons" :key="season.id" :value="season">
                {{ season.name }}
              </option>
            </BSelect>
          </div>
        </div>
      </div>
      <div v-if="!isRetroMode" class="mediaplayer-filters-league__title">2. Una opción de búsqueda:</div>
      <div class="mediaplayer-filters-league__searchcontainer">
        <div class="mediaplayer-filters-league__searchcontainer__contplayer" @click="$emit('selectLeague', 1)">
          <div class="mediaplayer-filters-league__searchcontainer__player"></div>
          <div class="mediaplayer-filters-league__searchcontainer__namebtn player_name">Player</div>
        </div>
        <div class="mediaplayer-filters-league__searchcontainer__contteam" @click="$emit('selectLeague', 0)">
          <div class="mediaplayer-filters-league__searchcontainer__team"></div>
          <div class="mediaplayer-filters-league__searchcontainer__namebtn team_name">Team</div>
        </div>
      </div>
    </div>
    <!-- VISTA 2 -->
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { BSelect } from 'buefy/dist/esm/select';

export default {
  components: {
    BSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listTournaments: [],
      tournamentSelected: {},
      seasonSelected: {},
    };
  },
  async created() {
    await this.fillListTournaments();
    const myDiv = document.getElementsByClassName('mediaplayer-filters-league');
    myDiv.scrollTop = 0;
  },
  watch: {
    tournamentSelected() {
      this.setTournament(this.tournamentSelected);
    },
    seasonSelected(newValue) {
      this.getSeasonInfo(newValue);
    },
    isRetroMode(newValue) {
      if (!newValue) {
        this.seasonSelected = this.tournamentSelected.seasons[0];
      }
    },
    isModalVisible(newValue) {
      if (newValue && !this.isRetroMode && this.lastSeason) {
        this.getSeasonInfo(this.seasonID);
      }
    },
  },
  computed: {
    ...mapState('mediaGeneral', ['tournaments', 'seasonID', 'lastSeason']),
  },
  methods: {
    ...mapMutations('mediaGeneral', ['setTournament', 'setSeason', 'setLastSeason']),
    ...mapActions('mediaGeneral', ['getTeamsBySeasons', 'getPlayersBySeasons']),
    getSeasonInfo(season) {
      this.setSeason(season);
      this.getTeamsBySeasons();
      this.getPlayersBySeasons({ seasonId: season.id });
    },
    async fillListTournaments() {
      try {
        if (this.tournaments) {
          for (let index = 0; index < this.tournaments.length; index++) {
            this.listTournaments.push({
              id: this.tournaments[index]._id,
              name: this.tournaments[index].tournament.name,
              seasons: this.tournaments[index].seasons,
            });
            if (this.tournaments[index]._id === 1) {
              this.tournamentSelected = {
                id: this.tournaments[index]._id,
                name: this.tournaments[index].tournament.name,
                seasons: this.tournaments[index].seasons,
              };
              this.setLastSeason(this.tournamentSelected.seasons[0]);
              if (!this.isRetroMode) {
                this.seasonSelected = this.tournamentSelected.seasons[0];
              }
            }
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    setTournamentSelected(val) {
      this.tournamentSelected = val;
      this.seasonSelected = this.tournamentSelected.seasons[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.mediaplayer-filters-league {
  width: 100%;
  height: auto;
  display: inline-block;
  &__title {
    width: 100%;
    height: 33px;
    margin-bottom: 40px;
    font-family: 'Avenir-Demi';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: -0.13px;
    text-align: center;
    color: #3e3e3e;
    @media screen and (max-width: 453px) {
      font-size: 20px;
    }
    @media screen and (max-width: 353px) {
      font-size: 16px;
    }
  }
  &__selectorscontainer {
    width: 836px;
    height: 105px;
    margin: 0 auto;
    margin-top: 66px;
    margin-bottom: 40px;
    border-radius: 8px;
    background-color: rgba(216, 216, 216, 0.2);
    display: inline-flex;
    justify-content: center;
    position: relative;
    @media screen and (max-width: 853px) {
      width: 700px;
    }
    @media screen and (max-width: 768px) {
      width: 600px;
    }
    @media screen and (max-width: 653px) {
      width: 553px;
      height: 211px;
      border-radius: 0px;
      display: inline-block;
      margin-top: 28px;
      background-color: transparent;
    }
    @media screen and (max-width: 553px) {
      width: 453px;
    }
    @media screen and (max-width: 453px) {
      width: 353px;
      margin-bottom: 20px;
      margin-top: 18px;
    }
    @media screen and (max-width: 353px) {
      width: 280px;
    }
    &__abs {
      width: 130%;
      position: absolute;
      height: 211px;
      visibility: hidden;
      margin-left: -15%;
      background-color: rgba(216, 216, 216, 0.2);
      @media screen and (max-width: 653px) {
        visibility: visible;
      }
    }
    &__league {
      width: 417px;
      height: 105px;
      display: inline-flex;
      justify-content: center;
      padding: 34px 42px 21px 65px;
      @media screen and (max-width: 853px) {
        padding: 34px 25px 21px 25px;
      }
      @media screen and (max-width: 768px) {
        padding: 34px 5px 21px 5px;
      }
      @media screen and (max-width: 653px) {
        padding: 0px 0px 0px 0px;
        display: inline-block;
        width: 100%;
      }
      &__label {
        width: 60px;
        height: 36px;
        font-family: 'Avenir-Demi';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        text-align: right;
        padding-right: 6px;
        color: #808285;
        @media screen and (max-width: 853px) {
          width: 98px;
        }
        @media screen and (max-width: 768px) {
          font-size: 17px;
          width: 79px;
        }
        @media screen and (max-width: 653px) {
          font-size: 16px;
          margin-left: 160px;
          text-align: left;
          margin-top: 20px;
        }
        @media screen and (max-width: 553px) {
          margin-left: 110px;
        }
        @media screen and (max-width: 453px) {
          margin-left: 60px;
        }
        @media screen and (max-width: 353px) {
          margin-left: 24px;
        }
      }
      &__selector {
        width: 232px;
        height: 36px;
        @media screen and (max-width: 853px) {
          width: 212px;
        }
        @media screen and (max-width: 768px) {
          width: 200px;
        }
        @media screen and (max-width: 653px) {
          width: 232px;
          margin-left: 160px;
        }
        @media screen and (max-width: 553px) {
          margin-left: 110px;
        }
        @media screen and (max-width: 453px) {
          margin-left: 60px;
        }
        @media screen and (max-width: 353px) {
          margin-left: 24px;
        }
      }
    }
    &__season {
      width: 417px;
      height: 105px;
      display: inline-flex;
      justify-content: center;
      padding: 34px 42px 21px 65px;
      @media screen and (max-width: 853px) {
        padding: 34px 25px 21px 25px;
      }
      @media screen and (max-width: 768px) {
        padding: 34px 5px 21px 5px;
      }
      @media screen and (max-width: 653px) {
        padding: 0px 0px 0px 0px;
        display: inline-block;
        width: 100%;
      }
      &__label {
        width: 120px;
        height: 36px;
        font-family: 'Avenir-Demi';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        color: #808285;
        text-align: right;
        padding-right: 6px;
        @media screen and (max-width: 853px) {
          width: 98px;
        }
        @media screen and (max-width: 768px) {
          font-size: 17px;
          width: 79px;
        }
        @media screen and (max-width: 653px) {
          font-size: 16px;
          margin-left: 160px;
          text-align: left;
        }
        @media screen and (max-width: 553px) {
          margin-left: 110px;
        }
        @media screen and (max-width: 453px) {
          margin-left: 60px;
        }
        @media screen and (max-width: 353px) {
          margin-left: 24px;
        }
      }
      &__selector {
        width: 232px;
        height: 36px;
        @media screen and (max-width: 853px) {
          width: 212px;
        }
        @media screen and (max-width: 768px) {
          width: 200px;
        }
        @media screen and (max-width: 653px) {
          width: 232px;
          margin-left: 160px;
        }
        @media screen and (max-width: 553px) {
          margin-left: 110px;
        }
        @media screen and (max-width: 453px) {
          margin-left: 60px;
        }
        @media screen and (max-width: 353px) {
          margin-left: 24px;
        }
      }
    }
    &__sep {
      width: 2px;
      height: 105px;
      &__divisor {
        width: 2px;
        height: 65px;
        border-left: 1px dotted #000;
        margin-top: 20px;
      }
      @media screen and (max-width: 653px) {
        display: none;
      }
    }
  }
  &__searchcontainer {
    width: 100%;
    height: 235px;
    margin: 0 48px 0 0;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      height: 215px;
    }
    @media screen and (max-width: 653px) {
      height: 195px;
    }
    @media screen and (max-width: 553px) {
      height: 175px;
    }
    @media screen and (max-width: 453px) {
      height: 155px;
    }
    @media screen and (max-width: 353px) {
      height: 135px;
    }
    &__namebtn {
      width: 100%;
      height: 16px;
      font-family: 'Avenir-Pro-Medium';
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 16px;
      letter-spacing: -0.13px;
      text-align: center;
      color: #4e4f4f;
      float: left;
    }
    &__contplayer {
      width: 298px;
      height: 235px;
      margin: 0 48px 0 0;
      display: inline-block;
      @media screen and (max-width: 768px) {
        width: 248px;
        height: 215px;
      }
      @media screen and (max-width: 653px) {
        width: 208px;
        height: 195px;
      }
      @media screen and (max-width: 553px) {
        width: 178px;
        height: 175px;
      }
      @media screen and (max-width: 453px) {
        width: 118px;
        height: 155px;
      }
      @media screen and (max-width: 353px) {
        width: 90px;
        height: 135px;
      }
    }
    &__player {
      width: 298px;
      height: 205px;
      padding: 41px 87px 40px;
      border-radius: 19px;

      background-color: #fff;
      display: inline-block;
      background-image: url('/images/soccer-player2.png');
      -webkit-background-size: 42%;
      -moz-background-size: 42%;
      -o-background-size: 42%;
      background-size: 42%;
      background-repeat: no-repeat;
      background-position: center center;
      border: solid 4px #e7ebef;
      &:hover {
        background-image: url('/images/soccer-player.png');
        border: solid 4px #ade696;
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        width: 248px;
        height: 185px;
      }
      @media screen and (max-width: 653px) {
        width: 208px;
        height: 165px;
      }
      @media screen and (max-width: 553px) {
        width: 178px;
        height: 145px;
        margin-left: 30px;
      }
      @media screen and (max-width: 453px) {
        width: 118px;
        height: 125px;
        margin-left: 10px;
        padding: 41px 70px 40px;
      }
      @media screen and (max-width: 353px) {
        width: 90px;
        height: 105px;
        margin-left: 10px;
        padding: 41px 55px 40px;
      }
    }
    &__contteam {
      width: 298px;
      height: 235px;
      margin: 0 0 0 48px;
      display: inline-block;
      @media screen and (max-width: 768px) {
        width: 248px;
        height: 215px;
      }
      @media screen and (max-width: 653px) {
        width: 208px;
        height: 195px;
      }
      @media screen and (max-width: 553px) {
        width: 178px;
        height: 175px;
      }
      @media screen and (max-width: 453px) {
        width: 118px;
        height: 155px;
      }
      @media screen and (max-width: 353px) {
        width: 90px;
        height: 135px;
      }
    }
    &__team {
      width: 298px;
      height: 205px;
      padding: 41px 87px 40px;
      border-radius: 19px;
      border: solid 4px #e7ebef;
      background-color: #fff;
      display: inline-block;
      background-image: url('/images/icn-teams2.png');
      -webkit-background-size: 42%;
      -moz-background-size: 42%;
      -o-background-size: 42%;
      background-size: 42%;
      background-repeat: no-repeat;
      background-position: center center;
      &:hover {
        background-image: url('/images/icn-teams.png');
        border: solid 4px #ade696;
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        width: 248px;
        height: 185px;
      }
      @media screen and (max-width: 653px) {
        width: 208px;
        height: 165px;
      }
      @media screen and (max-width: 553px) {
        width: 178px;
        height: 145px;
        margin-right: 30px;
        float: right;
      }
      @media screen and (max-width: 453px) {
        width: 118px;
        height: 125px;
        margin-right: 10px;
        float: right;
        padding: 41px 70px 40px;
      }
      @media screen and (max-width: 353px) {
        width: 90px;
        height: 105px;
        margin-right: 10px;
        float: right;
        padding: 41px 55px 40px;
      }
    }
  }
}
.player_name {
  @media screen and (max-width: 553px) {
    margin-left: 30px;
    float: left;
  }
  @media screen and (max-width: 453px) {
    margin-left: 20px;
    float: left;
  }
  @media screen and (max-width: 353px) {
    margin-left: 20px;
    float: left;
  }
}

.team_name {
  @media screen and (max-width: 553px) {
    margin-right: 30px;
    margin-top: 7px;
    float: right;
  }
  @media screen and (max-width: 453px) {
    margin-right: 20px;
    float: right;
  }
  @media screen and (max-width: 353px) {
    margin-right: 20px;
    float: right;
  }
}
</style>
