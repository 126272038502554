<template>
  <div>
    <div class="mediaplayer-filters-teams" v-if="show">
      <div class="mediaplayer-filters-teams__title">Team:</div>
      <div class="mediaplayer-filters-teams__teamscontainer-pc">
        <template v-for="(team, key) in getTeams">
          <div :class="'team-item nomargin-' + team['index']" :key="key" @click="clickTeamSelect(team['data'].team_id)">
            <div class="team-item__img ">
              <img class="team-item__img__source" :src="getTeamLogoUrl(team)" />
            </div>
            <div class="team-item__name">{{ team['data'].acronym }}</div>
          </div>
        </template>
      </div>
      <div class="mediaplayer-filters-teams__teamscontainer-movil">
        <BCarousel :autoplay="false" :pause-info="false" :arrow="false" :indicator-inside="false">
          <template v-for="(teamGroup, key) in getTeamsMobile">
            <BCarouselItem :key="key">
              <template v-for="(team, keyTeam) in teamGroup">
                <div
                  :key="keyTeam"
                  :class="'team-item nomargin-' + team['index']"
                  @click="clickTeamSelect(team['data'].team_id)"
                >
                  <div class="team-item__img ">
                    <img class="team-item__img__source" :src="getTeamLogoUrl(team)" />
                  </div>
                  <div class="team-item__name">{{ team['data'].acronym }}</div>
                </div>
              </template>
            </BCarouselItem>
          </template>
        </BCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters('mediaGeneral', ['getTeams', 'getTeamsMobile']),
  },
  methods: {
    ...mapMutations('mediaGeneral', ['setTeam']),
    clickTeamSelect(val) {
      this.setTeam(val);
      this.$emit('selectTeam');
    },
    getTeamLogoUrl(team) {
      if (this.isRetroMode) {
        return `https://az755631.vo.msecnd.net/teams-80/${team['data'].team_id}.png`;
      }
      return team['data'].logo;
    },
  },
  components: {
    BCarousel,
    BCarouselItem,
  },
};
</script>
<style lang="scss" scoped>
.team-item {
  float: left;
  width: 156px;
  height: 120px;
  margin: 0 13px 14px 0;
  padding: 16px 38px 8px;
  border-radius: 8px;
  border: solid 1px #dadada;
  background-color: #fff;

  &:nth-child(6n + 6),
  :nth-child(6) {
    margin: 0 0 0 0;
  }
  @media screen and (max-width: 1053px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 31px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 0 14px 0;
    }
    margin: 0 31px 23px 0;
  }
  @media screen and (max-width: 953px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 7px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 0px 23px 0;
    }
    margin: 0 7px 23px 0;
  }
  @media screen and (max-width: 853px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 34px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 34px 23px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 0 23px 0;
    }
    margin: 0 34px 23px 0;
  }
  @media screen and (max-width: 768px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 5px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 5px 23px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 0px 23px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 5px 23px 0;
    }
    margin: 0 5px 23px 0;
    width: 152px;
  }
  @media screen and (max-width: 653px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 20px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 20px 23px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 20px 23px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 0 23px 0;
    }
    margin: 0 20px 23px 0;
  }
  @media screen and (max-width: 553px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 2px 23px 0;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 2px 23px 0;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 2px 23px 0;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 0 23px 0;
    }
    margin: 0 2px 23px 0;
    padding: 16px 35px 8px;
    width: 142px;
  }
  @media screen and (max-width: 453px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 45px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 45px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 438px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 37px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 37px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 418px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 29px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 29px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 406px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 33px 23px 21px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 33px 23px 21px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 392px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 28px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 28px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 386px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 25px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 25px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 376px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 20px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 20px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 366px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 12px 23px 10px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 12px 23px 10px;
    padding: 16px 38px 8px;
    width: 156px;
  }
  @media screen and (max-width: 353px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 38px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 38px;
    padding: 16px 22px 8px;
    width: 122px;
  }
  @media screen and (max-width: 343px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 32px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 32px;
    padding: 16px 22px 8px;
    width: 122px;
  }
  @media screen and (max-width: 333px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 27px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 27px;
    padding: 16px 22px 8px;
    width: 122px;
  }
  @media screen and (max-width: 323px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 23px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 23px;
    padding: 16px 22px 8px;
    width: 122px;
  }
  @media screen and (max-width: 313px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 19px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 19px;
    padding: 16px 22px 8px;
    width: 122px;
  }
  @media screen and (max-width: 303px) {
    &:nth-child(6n + 6),
    :nth-child(6) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(5n + 5),
    :nth-child(5) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(4n + 4),
    :nth-child(4) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(3n + 3),
    :nth-child(3) {
      margin: 0 18px 23px 15px;
    }
    &:nth-child(2n + 2),
    :nth-child(2) {
      margin: 0 0px 23px 0;
    }
    margin: 0 18px 23px 15px;
    padding: 16px 22px 8px;
    width: 122px;
  }

  &:hover {
    filter: grayscale(0%);
    border: solid 1px #7ed321;
    cursor: pointer;
  }
  &__img {
    width: 80px;
    height: 80px;
    &__source {
      width: 80px;
      height: 80px;
    }
  }
  &__name {
    margin-top: 5px !important;
    margin-left: -35px;
    width: 150px;
    height: 12px;
    font-family: 'Avenir-Demi';
    font-size: 9.2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #57595d;
    text-transform: uppercase;
    @media screen and (max-width: 453px) {
      margin-left: -35px !important;
      float: left;
    }
  }
}
.mediaplayer-filters-teams {
  width: 96%;
  height: auto;
  display: inline-block;
  @media screen and (max-width: 768px) {
    width: 98%;
  }
  @media screen and (max-width: 653px) {
    width: 92%;
  }
  @media screen and (max-width: 553px) {
    width: 98%;
  }
  &__title {
    width: 100%;
    height: 33px;
    margin-bottom: 24px;
    margin-top: 0px;
    font-family: 'Avenir-Demi';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: -0.13px;
    text-align: center;
    color: #3e3e3e;

    @media screen and (max-width: 453px) {
      font-size: 20px;
    }
    @media screen and (max-width: 353px) {
      font-size: 16px;
    }
  }
  &__teamscontainer-pc {
    width: 100%;
    height: auto;
    display: inline-block;
    @media screen and (max-width: 453px) {
      display: none;
    }
  }
  &__teamscontainer-movil {
    text-align: center;
    display: none;
    background: #ececec;
    padding-top: 25px;
    padding-bottom: 25px;
    @media screen and (max-width: 453px) {
      display: inline-block;
    }
  }
}
</style>
